import React, { useState} from 'react';
import './App.css';
import logo from './logo.svg';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


function App() {
  const [monto, setMonto] = useState(10000);
  const [años, setAños] = useState(5);
  const tasaAnual = 44.86; // Tasa anualizada del servicio PAMM

  const handleMontoChange = (e) => setMonto(e.target.value);
  const handleAñosChange = (e) => setAños(e.target.value);

  // Función para calcular el valor futuro
  function calcularValorFuturo(monto, tasa, años) {
    return (monto * Math.pow(1 + tasa / 100, años)).toFixed(2);
  }

  const añosArray = Array.from({ length: años }, (_, i) => i + 1);
  const valoresFuturos = añosArray.map((año) => calcularValorFuturo(monto, tasaAnual, año));

  const data = {
    labels: añosArray,
    datasets: [
      {
        label: 'Valor Futuro de la Inversión',
        data: valoresFuturos,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <div className="App">
    <h1>Simulador de Inversión PAMM</h1>
      <div>
        <label>Monto de Inversión:</label>
        <input
          type="number"
          value={monto}
          onChange={handleMontoChange}
        />
      </div>
      <div>
        <label>Años de Inversión:</label>
        <input
          type="number"
          value={años}
          onChange={handleAñosChange}
          min="1"
        />
      </div>
      <h2>Proyección a {años} años:</h2>
      <p>Con un rendimiento anualizado neto del {tasaAnual}%: ${calcularValorFuturo(monto, tasaAnual, años)}</p>

      {/* Gráfico de Líneas */}
      <div style={{ width: '600px', margin: '0 auto' }}>
        <Line data={data} />
      </div>

      
      {/* Tabla de resultados Anuales*/}
      <table>
        <thead>
         <tr>
          <th>Año</th>
          <th>Valor Futuro</th>
         </tr>
        </thead>
        <tbody>
          {añosArray.map((año, index) => (
            <tr key={index}>
              <td>{año}</td>
              <td>${valoresFuturos[index]}</td>
        </tr>
    ))}
  </tbody>
</table>
 </div>
  );
}

export default App;
